'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");

function str(prim) {
  return prim;
}

function CopyButton(Props) {
  var textToCopy = Props.textToCopy;
  var label = Props.label;
  var match = React.useState(function () {
        return false;
      });
  var setCopied = match[1];
  React.useEffect(function () {
        var timer = setTimeout((function (param) {
                return Curry._1(setCopied, (function (param) {
                              return false;
                            }));
              }), 1000);
        return (function (param) {
                  clearTimeout(timer);
                  
                });
      });
  var handleClick = function (param) {
    window.navigator.clipboard.writeText(textToCopy);
    return Curry._1(setCopied, (function (param) {
                  return true;
                }));
  };
  if (match[0]) {
    return React.createElement("span", {
                className: " text-sm text-green-600 text-left font-semibold"
              }, "Copied!");
  } else {
    return React.createElement("button", {
                className: "text-sm text-left text-gray-700 hover:text-blue-500 focus:outline-none",
                onClick: handleClick
              }, label);
  }
}

var make = CopyButton;

exports.str = str;
exports.make = make;
/* react Not a pure module */
