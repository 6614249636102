'use strict';

var React = require("react");
var IconFirst = require("./iconFirst");

function transformIcons(prim) {
  IconFirst.transformIcons();
  
}

function PfIcon(Props) {
  var className = Props.className;
  React.useEffect((function () {
          IconFirst.transformIcons();
          
        }), [className]);
  return React.createElement("span", {
              key: className
            }, React.createElement("i", {
                  className: className
                }));
}

var make = PfIcon;

exports.transformIcons = transformIcons;
exports.make = make;
/* react Not a pure module */
